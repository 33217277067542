import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayButton from "../components/PlayButton/PlayButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroBox = styled(Box)(({ theme }) => ({
  height: 584,
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    height: "auto",
    padding: "0",
  },
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  margin: "120px 0 0 0",
  maxWidth: "450px",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    margin: 0,
    padding: "56px 24px",
    height: "auto",
  },
}));

const AboutUs = () => {
  const theme = useTheme();
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
        <HeroBox>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} lg={6}>
              <HeroBoxTop>
                <Typography variant="body2" component="p">
                  About us
                </Typography>
                <Typography
                  variant="h2"
                  component="p"
                  sx={{
                    fontStyle: "italic",
                    fontWeight: 700,
                    mt: 4,
                  }}
                >
                  Experience matters
                </Typography>
                <Typography variant="body1" component="p" sx={{ my: 3 }}>
                  A global team comprised of hundreds of RPMers spanning the US,
                  Europe, Mexico, & Canada, we proudly service some of the
                  greatest and most recognizable companies worldwide, tackling
                  their most difficult logistical and supply chain challenges.
                </Typography>
                <Button variant="contained" disableElevation href="/about-us">
                  More about us
                </Button>
              </HeroBoxTop>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{ height: "280px", display: "flex", alignItems: "center" }}
              >
                <Box
                  component="img"
                  src="/assets/about-us-7.webp"
                  alt="About us"
                  sx={{
                    width: "auto",
                    height: "584px",
                    position: "absolute",
                    right: "-330px",
                    bottom: 0,
                    zIndex: -1,
                    [theme.breakpoints.down("lg")]: {
                      height: "280px",
                      right: "50%",
                      transform: "translateX(50%)",
                    },
                  }}
                />
                <PlayButton videoID="hy57QEVpG90" channel="youtube" />
              </Box>
            </Grid>
          </Grid>
        </HeroBox>
      </Container>
    </Box>
  );
};

export default AboutUs;
