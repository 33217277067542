import { createTheme } from "@mui/material";
import "typeface-roboto-condensed";
import "typeface-roboto";

const RPM = createTheme({
  /******************************************
   * FONTS
   *****************************************/
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1312,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "Roboto",
    lineHeight: "1.25rem",
    body1: {
      fontWeight: 300,
      lineHeight: 1.25,
      letterSpacing: "-0.02em",
    },
    body2: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "-0.02em",
    },
    body3: {
      fontFamily: "Roboto Condensed",
      fontWeight: 700,
      fontSize: "0.75rem",
      lineHeight: 1.5,
      letterSpacing: "0.06em",
      fontStyle: "italic",
      textTransform: "uppercase",
    },
    body4: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: "0.75rem",
      lineHeight: 1.25,
      fontStyle: "normal",
    },
    body5: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: "0.875rem",
      lineHeight: 1.25,
      fontStyle: "normal",
    },
    h1: {
      fontFamily: "Roboto Condensed",
      fontSize: "2.5rem",
      lineHeight: "3rem",
      fontWeight: 700,
      textTransform: "uppercase",
      "@media (min-width:600px)": {
        fontSize: "4rem",
        lineHeight: "4.5rem",
      },
    },
    h2: {
      fontFamily: "Roboto Condensed",
      fontSize: "2.5rem",
      lineHeight: "3rem",
      textTransform: "uppercase",
      letterSpacing: "-0.02em",
      "@media (min-width:600px)": {
        fontSize: "3rem",
        lineHeight: "3.5rem",
      },
    },
    h3: {
      fontFamily: "Roboto Condensed",
      fontSize: "1.75rem",
      lineHeight: "2.25rem",
    },
    h4: {
      fontFamily: "Roboto Condensed",
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
  },
  /******************************************
   * COLORS
   *****************************************/
  palette: {
    neutral: {
      grey50: "#FBFBFB",
      grey100: "#EBEBEC",
      grey200: "#F6F6F6",
      grey300: "#A9A9A9",
      grey400: "#3C3C3C",
      grey500: "#797986",
      grey700: "#414149",
      grey900: "#141424",
    },
    primary: {
      main: "#111827",
      blue100: "#EAF0FA",
      blue200: "#A0B9E9",
      blue300: "#5A86D8",
      blue400: "#2957AD",
      blue500: "#1C3663",
      blue600: "#0F1F3E",
      blue700: "#0C1932",
      blue800: "#172B50",
      gunMetal: "#333B40",
      contrastText: "#D9D9D9",
    },
    secondary: {
      main: "#0076B6",
      bronze: "#C45A09",
    },
    success: {
      main: "#22C55E",
      light: "#86EFAC",
    },
    warning: {
      main: "#F59E0B",
      light: "#FCD34D",
    },
    error: {
      main: "#EF4444",
      light: "#FCA5A5",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          backgroundColor: "white",
          padding: "12px 16px",
          fontSize: "1rem",
          color: "#173162",
          height: "48px",
          textTransform: "none",
        },
        outlined: {
          backgroundColor: "white",
          padding: "12px 16px",
          borderRadius: 0,
          color: "#173162",
          border: "1px solid #797986",
          fontSize: "1rem",
          fontWeight: 400,
          textTransform: "none",
          lineHeight: 1,
          height: "48px",
          "&:hover": {
            border: "1px solid #797986",
            backgroundColor: "#f1f5fc",
          },
        },
        contained: {
          backgroundColor: "#173162",
          padding: "12px 16px",
          borderRadius: 0,
          color: "#ffffff",
          border: "1px solid #173162",
          fontSize: "1rem",
          fontWeight: 400,
          textTransform: "none",
          lineHeight: 1,
          height: "48px",
          "&:hover": {
            border: "1px solid #173162",
            backgroundColor: "#173162",
            opacity: "0.85",
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
          },
        },
      },
    },
  },
});

export default RPM;
