import React from "react";
import { Box, Container } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import FeatureCard from "../components/FeatureCard/FeatureCard";
import Truck from "../icons/Truck.svg";
import TruckHover from "../icons/TruckHover.svg";
import RobustNetwork from "../icons/RobustNetwork.svg";
import RobustNetworkHover from "../icons/RobustNetworkHover.svg";
import QualitySupport from "../icons/QualitySupport.svg";
import QualitySupportHover from "../icons/QualitySupportHover.svg";
import PersonCheckmark from "../icons/PersonCheckmark.svg";
import PersonCheckmarkHover from "../icons/PersonCheckmarkHover.svg";

const cards = [
  {
    header: "01",
    footer: "Specialized Solutions",
    icon: Truck,
    hoverIcon: TruckHover,
    hoverLabel: "Specialized Solutions",
    hoverText:
      "We utilize proprietary data and technology to provide specialized solutions that are customized to tackle your specific supply chain challenges.",
  },
  {
    header: "02",
    footer: "Robust Network",
    icon: RobustNetwork,
    hoverIcon: RobustNetworkHover,
    hoverLabel: "Robust Network",
    hoverText:
      "Leveraging a robust carrier network, we execute tens of thousands of transactions per month and deliver hundreds of thousands of vehicles per year through a combination of digital innovations and an unwavering commitment to customer success.",
  },
  {
    header: "03",
    footer: "Quality Support",
    icon: QualitySupport,
    hoverIcon: QualitySupportHover,
    hoverLabel: "Quality Support",
    hoverText:
      "When you have a problem, we are here to support you and help solve it!",
  },
  {
    header: "04",
    footer: "Single Point of Contact",
    icon: PersonCheckmark,
    hoverIcon: PersonCheckmarkHover,
    hoverLabel: "Single Point of Contact",
    hoverText:
      "RPM provides a single point of contact throughout every stage of the shipment process.",
  },
];

const HomeFeatureCards = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {cards.map((card, i) => (
          <FeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
            hoverIcon={card.hoverIcon}
            hoverLabel={card.hoverLabel}
            hoverText={card.hoverText}
          />
        ))}
      </Box>
      <Carousel
        interval={5000}
        swipe={false}
        navButtonsAlwaysVisible
        sx={{ textAlign: "center", display: { xs: "block", sm: "none" } }}
        navButtonsWrapperProps={{
          style: {
            display: "flex",
            alignItems: "center",
          },
        }}
        navButtonsProps={{
          style: {
            width: "40px",
            height: "40px",
            color: "#111827",
            backgroundColor: "#F6F6F6",
          },
        }}
        indicatorContainerProps={{
          style: {
            margin: "10px 0",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#C4C4C4",
            backgroundColor: "#ffffff",
            margin: "0 12px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#173162",
            backgroundColor: "#173162",
          },
        }}
      >
        {cards.map((card, i) => (
          <FeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
            hoverIcon={card.hoverIcon}
            hoverLabel={card.hoverLabel}
            hoverText={card.hoverText}
          />
        ))}
      </Carousel>
    </Container>
  );
};

export default HomeFeatureCards;
