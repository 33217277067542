import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography } from "@mui/material";
import SolutionsAccordion from "../components/SolutionsAccordion/SolutionsAccordion";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomeSolutions = () => {
  const items = [
    {
      label: "Quality & Service",
      body: "We are laser-like and focused on providing our customers with service-oriented solutions.",
      img: "/assets/information-technology.webp",
    },
    {
      label: "Agility",
      body: "Our non-asset based model allows us to scale to our customer's needs and meet demand, immediately.",
      img: "/assets/transportation-solutions.webp",
    },
    {
      label: "Visibility",
      body: "We use proprietary tools and software to provide customers with complete transparency from origin to destination.",
      img: "/assets/capacity-planning.webp",
    },
    {
      label: "Relationships",
      body: "We leverage a curated, global carrier network that fits the needs of the customers.",
      img: "/assets/strategic-planning.webp",
    },
    {
      label: "Support",
      body: "24/7/365, regardless of the time zone or country, our team is in constant communication with shippers and carriers to ensure we always provide quality and service that meets and exceeds expectations.",
      img: "/assets/network-design.webp",
    },
  ];

  const theme = useTheme();
  return (
    <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
      <Box sx={{ p: 4, my: 5 }}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Typography variant="body2" component="p">
              RPM Solutions
            </Typography>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                my: 4,
              }}
            >
              We Are a Disruptive and Differentiated End-to-End Transportation
              Service Provider.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={6}>
            <SolutionsAccordion items={items} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HomeSolutions;
