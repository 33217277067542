export const departments = [
  {
    value: "Freight Services",
    label: "Freight Services",
    services: [
      {
        value: "Request a Quote",
        label: "Request a Quote",
        email: "UScustomer@rpmmoves.com",
      },
      { value: "LTL", label: "LTL", email: "UScustomer@rpmmoves.com" },
      {
        value: "Chemical & Hazmat",
        label: "Chemical & Hazmat",
        email: "UScustomer@rpmmoves.com",
      },
      {
        value: "Expedited",
        label: "Expedited",
        email: "UScustomer@rpmmoves.com",
      },
      {
        value: "High & Heavy",
        label: "High & Heavy",
        email: "UScustomer@rpmmoves.com",
      },
      {
        value: "Oversized",
        label: "Oversized",
        email: "UScustomer@rpmmoves.com",
      },
      { value: "FTL", label: "FTL", email: "UScustomer@rpmmoves.com" },
      { value: "Goods", label: "Goods", email: "UScustomer@rpmmoves.com" },
    ],
  },
  {
    value: "Vehicle Services",
    label: "Vehicle Services",
    services: [
      {
        value: "Request a Quote",
        label: "Request a Quote",
        email: "UScustomer@rpmmoves.com",
      },
      { value: "OEM", label: "OEM", email: "UScustomer@rpmmoves.com" },
      {
        value: "Remarketing",
        label: "Remarketing",
        email: "UScustomer@rpmmoves.com",
      },
      { value: "Retail", label: "Retail", email: "UScustomer@rpmmoves.com" },
      { value: "Auction", label: "Auction", email: "UScustomer@rpmmoves.com" },
      {
        value: "Dealership",
        label: "Dealership",
        email: "UScustomer@rpmmoves.com",
      },
      {
        value: "POV / RPM Moves",
        label: "POV / RPM Moves",
        email: "cust.support@rpmmoves.com;UScustomer@rpmmoves.com",
      },
      { value: "Rental", label: "Rental", email: "UScustomer@rpmmoves.com" },
      {
        value: "Fleet Management",
        label: "Fleet Management",
        email: "UScustomer@rpmmoves.com",
      },
    ],
  },
  {
    value: "Freight Carrier",
    label: "Freight Carrier",
    services: [
      {
        value: "Request a Quote",
        label: "Request a Quote",
        email: "UScarrier@rpmmoves.com",
      },
      {
        value: "Operations Support",
        label: "Operations Support",
        email: "UScarrier@rpmmoves.com",
      },
      {
        value: "New Carrier Inquiry",
        label: "New Carrier Inquiry",
        email: "UScarrier@rpmmoves.com",
      },
    ],
  },
  {
    value: "Vehicle Carrier",
    label: "Vehicle Carrier",
    services: [
      {
        value: "Request a Quote",
        label: "Request a Quote",
        email: "UScarrier@rpmmoves.com",
      },
      {
        value: "Operations Support",
        label: "Operations Support",
        email: "UScarrier@rpmmoves.com",
      },
      {
        value: "New Carrier Inquiry",
        label: "New Carrier Inquiry",
        email: "UScarrier@rpmmoves.com",
      },
    ],
  },
  {
    value: "Accounting - Finance",
    label: "Accounting / Finance",
    services: [
      {
        value: "Customer Billing Inquiry",
        label: "Customer Billing Inquiry",
        email: "AR@LoadRPM.com",
      },
      {
        value: "Claims Support",
        label: "Claims Support",
        email: "acct.claimshelpdesk@rpmmoves.com",
      },
      {
        value: "Quick Pay Support",
        label: "Quick Pay Support",
        email: "status@loadrpm.com",
      },
    ],
  },
  {
    value: "Information Technology",
    label: "Information Technology",
    services: [
      {
        value: "RPM Hub App Support",
        label: "RPM Hub App Support",
        email: "UStech@rpmmoves.com",
      },
      {
        value: "RPM Marketplace Support",
        label: "RPM Marketplace Support",
        email: "UStech@rpmmoves.com",
      },
      {
        value: "Turvo Support",
        label: "Turvo Support",
        email: "UStech@rpmmoves.com",
      },
    ],
  },
  {
    value: "Marketing",
    label: "Marketing",
    services: [
      {
        value: "Media Coverage",
        label: "Media Coverage",
        email: "marketing@rpmmoves.com",
      },
      {
        value: "Public Relations",
        label: "Public Relations",
        email: "marketing@rpmmoves.com",
      },
      {
        value: "Sponsorships / Partnership Opportunity",
        label: "Sponsorships / Partnership Opportunity",
        email: "marketing@rpmmoves.com",
      },
    ],
  },
];
